import "victormono";

import Home from "./components/Home";

import "./App.scss";

const App = () => {
  return (
    <div className="app-container">
      <Home />
    </div>
  );
};

export default App;
