import "./styles.scss";

const Home = () => {
  return (
    <div className="home-wrapper">
      <div className="banner">
        <div className="title">Cà Phê Story</div>
        <div className="caption">Coming soon...</div>
      </div>
    </div>
  );
};

export default Home;
